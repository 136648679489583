import { DestroyRef, inject } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { UserService } from '../user.service';
import { UserRole } from '../../models/user-role';

/** Base for permission services. */
export class BasePermissionService {

	/** Destroy ref. */
	protected readonly destroyRef = inject(DestroyRef);

	private readonly userService = inject(UserService);

	/** User role. */
	protected readonly userRole$ = this.userService.currentUser$.pipe(
		map(user => user?.role),
		shareReplay({ bufferSize: 1, refCount: false }),
		takeUntilDestroyed(this.destroyRef),
	);

	/**
	 * Checks if user role is among provided.
	 * @param roles Roles.
	 */
	protected checkUserRole(roles: readonly UserRole[]): Observable<boolean> {
		return this.userRole$.pipe(
			map(role => role == null ? false : roles.includes(role)),
			shareReplay({ bufferSize: 1, refCount: false }),
			takeUntilDestroyed(this.destroyRef),
		);
	}
}
